const projects = [
  {
    id: 1,
    name: "Client Project",
    tagline: "Knowaste",
    image_url: "squarecrop.jpg",
    color: "#f6d7ba",
    image_window_url: "knowastelabel2.jpg",
    url: "https://github.com/andynology/knowaste-v2",
    project_link: "",
  },

  {
    id: 2,
    name: "The Missing Portrait Game",
    tagline: "Match the cards to uncover the stolen portrait",
    image_url: "biggerportraityellow.svg",
    color: "#f7d544",
    image_window_url: "dogportraittestlabel.jpg",
    url: "https://github.com/bridgetjpereira/Game",
    project_link: "Game",
  },

  {
    id: 3,
    name: "Hostile Aliens Game",
    tagline: "Game built in JavaScript",
    image_url: "Pink space.svg",
    color: "#f6d7ba",
    image_window_url: "hostilealiengamelabel2.jpg",
    url: "https://github.com/bridgetjpereira/Hostile-Alien-Game",
    project_link: "Hostile-Alien-Game",
  },

  {
    id: 4,
    name: "Ticket Tracker",
    tagline: "Device to keep track of assigned tickets",
    image_url: "squareblue.jpg",
    color: "#21a2d5",
    image_window_url: "tickettrackerlabel2.jpg",
    url: "https://github.com/bridgetjpereira/Employee-tracker",
    project_link: "Employee-tracker",
  },

  {
    id: 5,
    name: "Calculator",
    tagline: "Working calculator built in vanilla JavaScript",
    image_url: "finalgreenbigcalc.svg",
    color: "#9fcba0",
    image_window_url: "calculatormodaltestlabel2.jpg",
    url: "https://github.com/bridgetjpereira/calculator",
    project_link: "calculator",
  },

  {
    id: 6,
    name: "Brewdog",
    tagline: "Online catalogue of beers linked to Brewdog API",
    image_url: "headliner_punk_ipa_image_3_.png",
    color: "#21a2d5",
    image_window_url: "brewdogmodallabel3.jpg",
    url: "https://github.com/bridgetjpereira/brewdog-site",
    project_link: "brewdog-site",
  },
];

export default projects;
